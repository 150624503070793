import React from 'react'
import { Helmet } from "react-helmet"
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import PageHero from '../components/PageHero'
import MenuRoll from '../components/MenuRoll'

export const AllMenusPageTemplate = ({
  heroImage,
  heading
}) => (
  <>
    <PageHero Title={heading} Image={heroImage}/> 
    <div className="pageContent pageContent__noAnimate">
      <div className="pageContent__inner"> 
        <MenuRoll />    
      </div> 
    </div> 
  </>
)


const AllMenusPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <>    
      <Helmet>
        <meta charSet="utf-8" />
        <title>{frontmatter.meta.metaTitle}</title>
        <meta name="description" content={frontmatter.meta.metaDescription} />
      </Helmet>
      <Layout>
        <AllMenusPageTemplate
          heroImage={frontmatter.heroImage}
          heading={frontmatter.heading}
        />
      </Layout>
    </>
  )
}

AllMenusPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default AllMenusPage

export const pageQuery = graphql`
  query AllMenusPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "all-menus-page" } }) {
      frontmatter {
        heroImage {
          childImageSharp {
            fluid(maxWidth: 1080, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        } 
        heading
        meta{
          metaTitle
          metaDescription
        }
      }
    }
  }
`